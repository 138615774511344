import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from './layout';
import Seo from '../components/seo';
import STYLES from '../../constants/styles';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Content>
          <PublishDate>{post.frontmatter.date}</PublishDate>
          <h1>{post.frontmatter.title}</h1>

          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Content>
        <PostsNav>
          <div>
            {previous && (
              <NavArrow>
                <Link to={previous.fields.slug} rel="prev">
                  &#10094; Previous Post
                </Link>
              </NavArrow>
            )}
          </div>
          <div>
            {next && (
              <NavArrow>
                <Link to={next.fields.slug} rel="next">
                  Next Post &#10095;
                </Link>
              </NavArrow>
            )}
          </div>
        </PostsNav>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

const Content = styled.div`
  background-color: white;
  padding: 5%;
`;

const PublishDate = styled.small`
  color: ${STYLES.META_COLOR};
`;

const PostsNav = styled.ul`
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const NavArrow = styled.li`
  margin: 1.5rem 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
